import Nav from "./Nav";
import Footer from "./Footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faEnvelope,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";

interface MyFormValues {
  name: string;
  email: string;
  subject: string;
  message: string;
}

const contactSchema = Yup.object({
  name: Yup.string().required("Full Name is Required !"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required !"),
  subject: Yup.string().required("Subject is Required !"),
  message: Yup.string().required("Message is Required !"),
});

const Contact = () => {
  const initialValues: MyFormValues = {
    name: "",
    email: "",
    subject: "",
    message: "",
  };
  return (
    <div>
      <Nav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 contact-bg">
            <div className="row">
              <div className="col-md-10 offset-md-1">
                <div className="contact p-4 mt-6">
                  <p className="p-text text-purple">CONTACT US</p>
                  <h1 className="text-purple">GET IN TOUCH WITH US</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-10 offset-md-1">
            <div className="message">
              <div className="row">
                <div className="col-md-4">
                  <table className="table table-borderless">
                    <tbody>
                      <tr>
                        <td>
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#9b40e0" }}
                          />
                        </td>
                        <td>
                          <strong>Office</strong>
                        </td>
                      </tr>
                      <tr>
                        <td></td>
                        <td>
                          Operational Office: B3 Modupeola Court, 9/11 Ayotola
                          Osolanke Close, Boet Estate Adeniyi Jones, Ikeja,
                          Lagos
                        </td>
                      </tr>
                      <tr>
                        <td className="pdt-4">
                          <FontAwesomeIcon
                            icon={faUser}
                            style={{ color: "#9b40e0" }}
                          />
                        </td>
                        <td className="pdt-4">
                          <strong>Contacts</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            style={{ color: "#9b40e0" }}
                          />
                        </td>
                        <a
                          href="mailto:support@appiawave.com"
                          className="text-black"
                        >
                          <td style={{ paddingTop: "0.5rem" }}>
                            support@appiawave.com
                          </td>
                        </a>
                      </tr>
                      <tr>
                        <td>
                          <FontAwesomeIcon
                            icon={faPhoneAlt}
                            style={{ color: "#9b40e0" }}
                          />
                        </td>
                        <td>+234(0) 816 531 2503</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="col-md-8 pd-4">
                  <h2>Send us a message</h2>
                  <p>We'll try to get back to you as soon as we can</p>
                  <Formik
                    initialValues={initialValues}
                    validationSchema={contactSchema}
                    onSubmit={async (values, { resetForm }) => {
                      try {
                        const headers = {
                          "Content-Type": "Application/json",
                          Authorization: "Bearer Token",
                        };
                        const body = JSON.stringify(values);
                        const response = await axios.post(
                          "https://api.appiawave.com/misc/contact",
                          body,
                          { headers }
                        );

                        if (response) {
                          if (response.data.success) {
                            swal(
                              "Great Job!",
                              response.data.message,
                              "success"
                            );
                            resetForm({
                              values: {
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                              },
                            });
                          } else {
                            swal("Oops", response.data.message, "error");
                          }
                        } else {
                          swal(
                            "Oops",
                            "something went wrong with your network",
                            "error"
                          );
                        }
                      } catch (error) {
                        console.log(error);
                      }
                    }}
                  >
                    {({ touched, errors, isSubmitting }) => (
                      <Form className="row g-3">
                        <div className="col-md-12">
                          <Field
                            type="text"
                            id="name"
                            name="name"
                            className="form-control form-control-lg"
                            placeholder="Name"
                            required
                          />
                          {errors.name && touched.name ? (
                            <div className="text-danger">{errors.name}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12">
                          <Field
                            type="email"
                            id="email"
                            name="email"
                            className="form-control form-control-lg"
                            placeholder="Email address"
                            required
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12">
                          <Field
                            type="text"
                            id="subject"
                            name="subject"
                            className="form-control form-control-lg"
                            placeholder="Subject"
                            required
                          />
                          {errors.subject && touched.subject ? (
                            <div className="text-danger">{errors.subject}</div>
                          ) : null}
                        </div>
                        <div className="col-md-12">
                          <Field
                            as="textarea"
                            type="text"
                            id="message"
                            name="message"
                            className="form-control form-control-lg"
                            placeholder="Message"
                            required
                          ></Field>
                          {errors.message && touched.message ? (
                            <div className="text-danger">{errors.message}</div>
                          ) : null}
                        </div>
                        <div className="col-12">
                          <button
                            className="btn btn-purple text-white float-end bttn-submit"
                            type="submit"
                          >
                            Submit
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid mt-5"
        style={{ marginRight: 0, marginLeft: 0 }}
      >
        <div className="map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1692.2311271604515!2d3.3416884014490678!3d6.608545367557499!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b93474b870d01%3A0x959a78a2e838043e!2sInvesta.ng!5e0!3m2!1sen!2sng!4v1620214669659!5m2!1sen!2sng"
            title="investa-location"
            style={{ width: "100%", height: "100vh" }}
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
