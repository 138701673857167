import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Nav from "../Nav";
import Footer from "../Footer";
import Header from "../Header";
import Partners from "../Partners";
import CarouselAppia from "../CarouselAppia";
import MakePayment from "../MakePayment";
import ManageTime from "../ManageTime";
import Security from "../Security";
import OtherPartners from "../OtherPartners";

library.add(fab);

const Home = () => {
  return (
    <div>
      <Nav />
      <Header />
      <Partners />
      <CarouselAppia />
      <MakePayment />
      <ManageTime />
      <Security />
      <OtherPartners />
      <Footer />
    </div>
  );
};

export default Home;
