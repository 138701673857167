import HuaweiLogo from "./images/Huawei.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const ManageTime = () => {
  return (
    <StyledDiv>
      <div className="container bg-d text-center">
        <div>
          <div className="manage-time">
            <h2 className="text-white fw-bold">
              Manage Your Time With Convenience
            </h2>
            <p className="text-white fs-5 mt-4 p">
              Making Online Transaction Easy, Accessible and Secure For You. Pay
              your utility bills, send and receive funds with ease all on the
              same app.
            </p>
            <div className="app-icon mt-5">
              <a
                href="https://play.google.com/store/apps/details?id=com.appia.ng"
                className="google-app"
                target="_blank"
                rel="noreferrer"
              >
                <button className="google">
                  <FontAwesomeIcon
                    icon={["fab", "google-play"]}
                    className="google-icon"
                  />
                  <div className="google-text">
                    <p>Get it on</p>
                    <h5>Google Play</h5>
                  </div>
                </button>
              </a>
              <a
                href="https://apps.apple.com/us/app/appiang/id1643401938"
                className="ios-app"
                target="_blank"
                rel="noreferrer"
              >
                <button className="ios">
                  <FontAwesomeIcon
                    icon={["fab", "apple"]}
                    className="ios-icon"
                  />
                  <div className="ios-text">
                    <p>Get it on</p>
                    <h4>App Store</h4>
                  </div>
                </button>
              </a>
              <a
                href="https://url.cloud.huawei.com/iwRSfDRJL2?shareTo=qrcode"
                target="_blank"
                rel="noreferrer"
                className="ios-app"
              >
                <button className="ios">
                  <img src={HuaweiLogo} className="ios-icon" alt="icon" />
                  <div className="ios-text">
                    <p>Available on the</p>
                    <h4>AppGallery</h4>
                  </div>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 3rem 12rem;

  .manage-time {
    padding: 3rem 15rem;
  }

  .app-icon .google-app {
    margin-right: 0rem;
  }

  .google-text {
    p {
      margin-bottom: 0rem;
      color: #7c8087;
      font-size: 1rem;
      text-align: left;
    }
    h5 {
      font-size: 1.2rem;
      margin-bottom: 0rem;
      font-weight: 600;
    }
  }

  .ios-text {
    p {
      margin-bottom: 0rem;
      color: #7c8087;
      font-size: 1rem;
      text-align: left;
    }
    h4 {
      font-size: 1.2rem;
      margin-bottom: 0rem;
      font-weight: 600;
      margin-top: 0rem;
      margin-left: 0rem;
    }
  }

  .google {
    display: flex;
    gap: 0.7rem;
  }
  .ios {
    display: flex;
    gap: 0.7rem;
  }

  .app-icon {
    margin-left: 0rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0.6rem;
    justify-content: center;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .manage-time {
      padding: 3rem 0rem 1rem 0rem;
    }

    .bg-d {
      height: 550px !important;
    }

    .app-icon {
      display: block !important;
      margin-left: 0.5rem !important;
    }

    .app-icon {
      margin-left: 0rem !important;
      display: grid !important;
      grid-template-columns: 1fr;
      gap: 0.3rem;
    }

    .app-icon .google-app {
      margin-right: 0rem;
    }

    .google-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h4 {
        font-size: 0.9rem;
        margin-bottom: 0rem;
        font-weight: 600;
      }
    }

    .ios-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h4 {
        font-size: 0.9rem;
        margin-bottom: 0rem;
        font-weight: 600;
        margin-top: 0rem;
        margin-left: 0rem;
      }
    }

    .google {
      display: flex;
      gap: 0.4rem;
      padding: 0.4rem;
      max-width: 12rem;
      margin: 0 auto;
    }
    .ios {
      display: flex;
      gap: 0.4rem;
      padding: 0.4rem;
      max-width: 12rem;
      margin: 0 auto;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 2rem 2rem 2rem;

    .manage-time {
      padding: 3rem 0rem 1rem 0rem;
    }

    .app-icon {
      display: flex !important;
      gap: 1rem;
    }
  }
`;

export default ManageTime;
