import styled from "styled-components";
import logo from "./images/logo.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HuaweiLogo from "./images/Huawei.svg";

const ReferralScreen = () => {
  return (
    <StyledDiv>
      <div className="referrals">
        <div className="referrals__logo">
          <Link className="navbar-brand text-logo" to="/">
            <img src={logo} alt="APPIA-Logo" /> AppiaApp{" "}
          </Link>
        </div>

        <div className="referrals__content">
          <h5>Welcome</h5>

          <p className="mt-4 letter">
            Here at AppiaNG, we are committed to providing you with the best
            possible and easy to use financial service/solution. We take pride
            in the fact that our payment gateway has unique features that allow
            users transact seamlessly with affordable tariffs and easy to use
            mobile application.
          </p>
          <p className="mt-4 letter">
            To enjoy a seamless experience, please download the app here;
          </p>
          <p className="mt-4 letter">
            Should you have any questions or inquiries about our product and
            services, out support team will be happy to attend to them, shoot us
            a mail at{" "}
            <a href="mailto:support@appiawave.com" className="email">
              support@appiawave.com
            </a>
          </p>
          <p className="mt-4 mb-0 letter">Thanks,</p>
          <p className="mt-0 letter"> Download our App here</p>

          <div className="download-links">
            <a
              href="https://play.google.com/store/apps/details?id=com.appia.ng"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={["fab", "google-play"]}
                className="google-icon"
              />
              <div>
                <p>Download</p>
                <h2>Google Play</h2>
              </div>
            </a>

            <a
              href="https://apps.apple.com/us/app/appiang/id1643401938"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon icon={["fab", "apple"]} className="ios-icon" />
              <div>
                <p>Download</p>
                <h2>Apple Store</h2>
              </div>
            </a>

            <a
              href="https://url.cloud.huawei.com/iwRSfDRJL2?shareTo=qrcode"
              target="_blank"
              rel="noreferrer"
            >
              <img src={HuaweiLogo} className="ios-icon" alt="icon" />
              <div>
                <p>Available on the</p>
                <h2>AppGallery</h2>
              </div>
            </a>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 8rem 20rem 4rem 20rem;

  .email {
    color: #0d6efd;
  }

  .referrals {
    background: #f8fafc;
    padding: 2rem 3rem;

    &__logo {
      display: flex;
      justify-content: center;
      margin-bottom: 2rem;
    }

    &__content {
      background: #ffffff;
      padding: 2rem;

      h5 {
        font-weight: 600;
        color: #121a26;
      }
    }
  }

  .letter {
    color: #384860;
    font-size: 1.2rem;
  }
  .download-links {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    margin-top: 3rem;

    a {
      display: flex;
      gap: 1rem;
      background: #ffffff;
      border: 2px solid #e2e4e8;
      border-radius: 5px;
      padding: 0.5rem;
      align-items: center;

      p {
        color: #7c8087;
        margin: 0rem;
      }
      h2 {
        color: #1d293f;
        font-size: 1.3rem;
        font-weight: 600;
      }
    }
  }

  .google {
    width: 100% !important;
  }

  .ios {
    width: 100% !important;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 7rem 1rem 3rem 1rem;

    .referrals {
      padding: 2rem 1rem;

      .download-links {
        display: grid;
        grid-template-columns: 1fr;
        gap: 1rem;
        margin-top: 3rem;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 7rem 1rem 3rem 1rem;
  }
`;

export default ReferralScreen;
