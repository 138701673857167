import shield from "./images/shield.svg";
import styled from "styled-components";

const Security = () => {
  return (
    <StyledDiv>
      <div className="row security">
        <div className="col-md-7 mt-5">
          <h1 className=" text-text text-white">
            We Take Your Security And Privacy Seriously
          </h1>
          <div className="security-check">
            <span className="fa fa-check check-icon"></span>
            <div className="check-text">
              <h1>Advanced Fraud Detection</h1>
              <p>
                Your data is stored and encrypted using strong cryptography. We
                scan our systems daily against security threats.
              </p>
            </div>
          </div>
          <div className="security-check">
            <span className="fa fa-check check-icon"></span>
            <div className="check-text">
              <h1>Your Money Is Safe With Us</h1>
              <p>
                We are compliant with DCI and PSS. Your transactions are always
                encrypted and secured.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="shield-img">
            <img src={shield} alt="APP" className="img-fluid" />
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .security {
      padding: 3rem 2rem 2rem 2rem;
    }
  }
`;

export default Security;
