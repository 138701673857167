import clients from "./images/clients.png";
import styled from "styled-components";

const OtherPartners = () => {
  return (
    <StyledDiv>
      <div className="container-fluid mb-5">
        <div className="row">
          <div className="text-center">
            <p className="fs-5 text-muted pd-t">
              The world's most innovative companies are our partners
            </p>
            <img src={clients} alt="CLIENTS" className="img-fluid" />
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div``;

export default OtherPartners;
