import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Home from "./components/pages/Home";
import Privacy from "./components/Privacy";
import Contacts from "./components/Contact";
import "./App.css";
import Referrals from "./components/pages/referrals";
import Invite from "./components/pages/invite";

function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/invite/:id" component={Invite} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/contact" component={Contacts} />
          <Route exact path="/download" component={Referrals} />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
}

export default App;
