import { useState, useEffect } from "react";
import styled from "styled-components";
import referral from "../components/images/Referral.svg";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";

interface RefferalValues {
  email: string;
  code: string;
}

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required !"),
});

const Join = () => {
  const initialValues: RefferalValues = { email: "", code: "" };
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname) {
      const userCode = history.location.pathname.split("invite/")[1];
      setCode(userCode);
    }
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (values: any, { resetForm }: any) => {
    try {
      setLoading(true);
      const payload = {
        email: values.email,
        code: code,
      };

      const headers = {
        "Content-Type": "Application/json",
      };
      const body = JSON.stringify(payload);
      const response = await axios.post(
        "https://api.appiawave.com/users/log_referrer",
        body,
        { headers }
      );

      if (response) {
        setLoading(false);
        resetForm({
          values: {
            email: "",
            code: "",
          },
        });
        if (response.data.success) {
          swal("Successful!!", response.data.message, "success");
          resetForm({
            values: {
              email: "",
              code: "",
            },
          });
          history.push("/download");
        }
      }
    } catch (e: any) {
      console.log(e);
      setLoading(false);
      if (e && e.response) {
        swal("Oops", e?.response?.data?.message, "error");
      } else {
        swal("Oops", "cannot make a request at the moment", "error");
      }

      resetForm({
        values: {
          email: "",
          code: "",
        },
      });
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema,
  });

  return (
    <StyledDiv>
      <div className="join">
        <div>
          <img src={referral} alt="graphics" />
        </div>
        <div>
          <div className="first-ref">Join over 15,000+ to use AppiaNG</div>
          <div className="second-ref mt-3">
            Join me on AppiaNG and enjoy free Bank transfers
          </div>
          <form onSubmit={formik.handleSubmit} className="mt-3 third-ref">
            <div>
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter email address to get started"
                type="email"
                name="email"
              />
            </div>
            <button
              disabled={formik.values.email && !loading ? false : true}
              type={"submit"}
              className="mt-3"
            >
              {loading ? "Loading..." : "Get Started"}
            </button>
          </form>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 3rem 9rem;
  .join {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .first-ref {
    color: #f77321;
    font-size: 0.9rem;
    font-weight: 500;
  }

  .second-ref {
    font-weight: 800;
    font-size: 1.8rem;
    line-height: 2.5rem;
    color: #1d293f;
  }

  .third-ref {
    input {
      background: #ffffff;
      border: 1px solid #f2f2f2;
      box-shadow: 0px 37.11px 59.06px rgba(0, 0, 0, 0.05);
      border-radius: 12px;
      padding: 1rem;
      width: 80%;
    }
    input::placeholder {
      color: #5b0ddf;
      font-size: 0.9rem;
    }
    button {
      background: #712dd6;
      border-radius: 5px;
      border: none;
      padding: 0.8rem 2.5rem;
      color: #ffffff;
    }
    button:disabled {
      cursor: wait;
      opacity: 0.5;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .join {
      display: grid;
      grid-template-columns: 1fr;
    }
    img {
      width: 17rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 2rem 2rem 2rem;

    img {
      width: 100%;
      margin-bottom: 3rem;
    }
  }
`;

export default Join;
