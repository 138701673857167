import { useState } from "react";
import axios from "axios";
import swal from "sweetalert";
import styled from "styled-components";

const Subscribe = () => {
  const [input, setInput] = useState({ email: "" });

  const handleChange = (e: any) => {
    setInput({ ...input, email: e.target.value });
  };
  const submitButton = async (e: any) => {
    e.preventDefault();
    const headers = {
      "Content-Type": "Application/json",
      Authorization: "Bearer Token",
    };
    const body = JSON.stringify(input);
    const response = await axios.post(
      "https://api.appiawave.com/misc/subscribe",
      body,
      { headers }
    );
    if (response) {
      if (response.data.success) {
        swal("Great Job!", response.data.message, "success");
        setInput({ email: "" });
      } else {
        swal("Oops", response.data.message, "error");
      }
    } else {
      swal("Oops", "something went wrong with your network", "error");
    }
  };

  return (
    <StyledDiv className="" id="get">
      <div className="bg-subscribe text-center align-items-center">
        <div className="row align-items-center">
          <div className="col-md-6 text-white first-item">
            Subscribe to Our Newsletter
          </div>
          <div className="col-md-6 text-center form-container">
            <form onSubmit={submitButton}>
              <div className="form d-flex form-area align-items-center">
                <span className="fa fa-envelope-o form-icon text-muted"></span>
                <input
                  value={input.email}
                  onChange={handleChange}
                  type="email"
                  className="form-control form-pd"
                  placeholder="Enter your email"
                />
                <button type="submit" className="sub-btn">
                  {" "}
                  Subscribe{" "}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 0rem 12rem;
  margin-top: -3rem;

  .first-item {
    font-weight: 400;
    font-size: 1.4rem;
  }

  .form-area {
    background: #fff;
    border-radius: 8px;
  }

  .sub-btn {
    border: none;
    padding: 1rem 1rem;
    color: #fff;
    background: #258aff;
    margin-right: 0.5rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0rem 2rem;
    margin-top: 3rem;

    .bg-subscribe {
      padding: 8px 10px 15px 36px !important;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0rem 2rem;
  }
`;

export default Subscribe;
