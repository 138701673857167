import { useState } from "react";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";
import NigeriaFlag from "./images/NigeriaFlag.svg";
import styled from "styled-components";
import Select from "react-select";

const Nav = () => {
  const [nav, setNav] = useState(false);
  const optionsList = [{ value: "Nigeria", label: "" }];

  return (
    <StyledDiv>
      <nav className="navbar navbar-expand-lg navbar-light bt-border p-3">
        <div className="container">
          <Link className="navbar-brand text-logo" to="/">
            <img src={logo} alt="APPIA-Logo" /> AppiaApp{" "}
          </Link>
          <button
            onClick={() => setNav(!nav)}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          {!nav ? (
            <div
              className="collapse navbar-collapse mt-lc"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item nav-space">
                  <Link className="nav-link " to="/">
                    Home
                  </Link>
                </li>
                <li className="nav-item nav-space">
                  <Link className="nav-link" to="/contact">
                    Contact Us
                  </Link>
                </li>
              </ul>
              <Link
                to="/"
                onClick={(e) => {
                  let hero = document.getElementById("download");
                  e.preventDefault(); // Stop Page Reloading
                  hero && hero.scrollIntoView();
                }}
                className="btn btn-fill nav-btn btn-round d-flex"
              >
                Get Started for Free
              </Link>
              <Select
                defaultValue={{ value: "Nigeria", label: "" }}
                options={optionsList}
                formatOptionLabel={(optionsList) => (
                  <div className="country-option">
                    <img src={NigeriaFlag} alt="flag" />
                    <span>{optionsList.label}</span>
                  </div>
                )}
              />
            </div>
          ) : (
            <div className="sidebar">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item nav-space">
                  <Link className="nav-link" to="/">
                    Home
                  </Link>
                </li>
                <li>
                  <Link className="nav-link" to="/contact">
                    Contact
                  </Link>
                </li>
              </ul>

              <Link
                to="/"
                onClick={(e) => {
                  let hero = document.getElementById("download");
                  e.preventDefault(); // Stop Page Reloading
                  hero && hero.scrollIntoView();
                }}
                className="btn btn-fill nav-btn btn-round d-flex"
              >
                Get Started for Free
              </Link>
              <Select
                defaultValue={{ value: "Nigeria", label: "" }}
                options={optionsList}
                formatOptionLabel={(optionsList) => (
                  <div className="country-option">
                    <img src={NigeriaFlag} alt="flag" />
                    <span>{optionsList.label}</span>
                  </div>
                )}
              />
            </div>
          )}
        </div>
      </nav>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  select option[value=""] {
    background-image: url(NigeriaFlag);
  }

  .css-1s2u09g-control {
    border: none;
    margin-left: 2rem;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-1pahdxg-control {
    border: none;
    margin-left: 2rem;
  }

  #react-select-3-listbox {
    background-color: #fff;
    width: 100%;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    .btn-fill {
      width: 70% !important;
      padding: 1rem 2rem;
      text-align: center;
    }

    .css-1pahdxg-control,
    .css-1s2u09g-control {
      width: 30% !important;
      margin-left: 0rem !important;
      margin-top: 1rem !important;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .sidebar {
      display: block;
    }

    .css-1pahdxg-control,
    .css-1s2u09g-control {
      width: 30% !important;
      margin-left: 0rem !important;
      margin-top: 1rem !important;
    }
  }
`;
export default Nav;
