import styled from "styled-components";
import Nav from "../Nav";
import FooterBase from "../FooterBase";
import ReferralScreen from "../RefferalScreen";

const Referrals = () => {
  return (
    <StyledDiv>
      <Nav />
      <ReferralScreen />
      <FooterBase />
    </StyledDiv>
  );
};

const StyledDiv = styled.div``;

export default Referrals;
