import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown } from "@fortawesome/free-solid-svg-icons";
import DownloadApp from "./images/DownloadApp.svg";
import Register from "./images/Register.svg";
import StartTransaction from "./images/StartTransaction.svg";
import ArrowUp from "./images/ArrowUp.svg";
import ArrowDown from "./images/ArrowDown.svg";

const MakePayment = () => {
  return (
    <StyledDiv>
      <div className="container mt-6" id="download">
        <div className="payment">
          <h3 className="fw-bold text-center">
            Want to make payment, send and receive funds without the stress of
            logging in into different apps?
          </h3>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <p className="text-center fs-5">Take these steps now!!</p>
          <p className="text-center">
            <FontAwesomeIcon className="fa-down" icon={faAngleDoubleDown} />
          </p>
          <div className="col-md-4 mt-5">
            <div className="step">
              <div className="content-box">
                <div className="box">
                  <img src={DownloadApp} alt="icon" />
                </div>
                <div className="mt-3">
                  <img src={ArrowUp} alt="icon" />
                </div>
              </div>
              <div className="steps">
                <h4 className="fw-bold">Download app</h4>
                <p className="mt-2">Available on Android and iOS devices.</p>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="step2">
              <div className="content-box">
                <div className="box">
                  <img src={Register} alt="icon" />
                </div>
                <div>
                  <img src={ArrowDown} alt="icon" />
                </div>
              </div>
              <div>
                <h4 className="fw-bold">Registration</h4>
                <p className=" mt-2">Registration takes less than a minute.</p>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="step3">
              <div className="box">
                <img src={StartTransaction} alt="icon" />
              </div>
              <div className="steps">
                <h4 className="fw-bold">Start Transacting</h4>
                <p className="mt-2">
                  Start making seamless transactions and earning points on the
                  platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 3rem 12rem;
  .payment {
    width: 34.2rem;
    margin: 0 auto;
    text-align: center;
  }

  .box {
    height: 5rem;
    width: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(180deg, #8e2de2 0%, #4a00e0 100%);
    box-shadow: 0px 32px 54px rgba(37, 138, 255, 0.224553);
    border-radius: 10px;
  }

  .content-box {
    display: flex;
    gap: 2rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .steps {
      margin-top: 1rem;
    }

    img {
      width: 10rem;
    }

    .payment {
      width: 100%;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 2rem 2rem 2rem;

    img {
      width: 6rem;
    }
  }
`;

export default MakePayment;
