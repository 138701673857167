import styled from "styled-components";
import HuaweiLogo from "./images/Huawei.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const appiaVideo = require("./videos/AppiaVideo.mp4");

const Header = () => {
  return (
    <StyledDiv>
      <div className="row home-bg">
        <div className="col-md-7">
          <h1 className="mt-6 h1-text text-white">
            Transact Hassle free with Appia
          </h1>
          <p className="fs-5 mt-4 text-white para">
            Appia opens you to a world of ease in sending and receiving money,
            transacting seamlessly with vendors and paying for utilities in
            seconds. Enjoy affordable rates and 5 free transfers when you sign
            up today.
          </p>
          <div className="app-icon mt-5">
            <a
              href="https://play.google.com/store/apps/details?id=com.appia.ng"
              className="google-app"
              target="_blank"
              rel="noreferrer"
            >
              <button className="google">
                <FontAwesomeIcon
                  icon={["fab", "google-play"]}
                  className="google-icon"
                />
                <div className="google-text">
                  <p>Get it on</p>
                  <h5>Google Play</h5>
                </div>
              </button>
            </a>
            <a
              href="https://apps.apple.com/us/app/appiang/id1643401938"
              className="ios-app"
              target="_blank"
              rel="noreferrer"
            >
              <button className="ios">
                <FontAwesomeIcon icon={["fab", "apple"]} className="ios-icon" />
                <div className="ios-text">
                  <p>Get it on</p>
                  <h4>App Store</h4>
                </div>
              </button>
            </a>
            <a
              href="https://url.cloud.huawei.com/iwRSfDRJL2?shareTo=qrcode"
              target="_blank"
              rel="noreferrer"
              className="ios-app"
            >
              <button className="ios">
                <img src={HuaweiLogo} className="ios-icon" alt="icon" />
                <div className="ios-text">
                  <p>Available on the</p>
                  <h4>AppGallery</h4>
                </div>
              </button>
            </a>
          </div>
        </div>
        <div className="col-md-5">
          <div className="video">
            <video width="320" height="446" autoPlay loop muted>
              <source src={appiaVideo.default} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  background: #f9f9f9;
  padding: 9rem 12rem 4rem 8rem;
  .home-bg {
    padding: 5rem 3rem 5rem 6rem;
    background: linear-gradient(180deg, #8e2de2 0%, #4a00e0 100%);
    align-items: center;
    border-radius: 8px;
  }
  .video {
    border: 1px solid #ffffff;
    height: 28rem;
    width: 25rem;
    border-radius: 0.6rem;
    background: linear-gradient(180deg, #8e2de2 0%, #4a00e0 100%);
    margin-left: 10rem;
  }
  video {
    width: 100%;
    height: 100%;
    border-radius: 0.6rem;
  }
  .h1-text {
    font-family: "Poppins", sans-serif;
    font-size: 3.7rem;
    line-height: 62px;
    font-weight: 800;
    color: #ffffff !important;
  }

  .app-icon .google-app {
    margin-right: 0rem;
  }

  .google-text {
    p {
      margin-bottom: 0rem;
      color: #7c8087;
      font-size: 1rem;
      text-align: left;
    }
    h5 {
      font-size: 1.2rem;
      margin-bottom: 0rem;
      font-weight: 600;
    }
  }

  .ios-text {
    p {
      margin-bottom: 0rem;
      color: #7c8087;
      font-size: 1rem;
      text-align: left;
    }
    h4 {
      font-size: 1.2rem;
      margin-bottom: 0rem;
      font-weight: 600;
      margin-top: 0rem;
      margin-left: 0rem;
    }
  }

  .google {
    display: flex;
    gap: 0.7rem;
  }
  .ios {
    display: flex;
    gap: 0.7rem;
  }

  .app-icon {
    margin-left: 0rem !important;
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .home-bg {
      padding: 2rem 1rem;
    }

    .video {
      height: 18rem;
      width: 100%;
      margin-left: 0rem;
      margin-top: 3rem;
    }

    .app-icon {
      margin-left: 0rem !important;
      display: grid !important;
      grid-template-columns: 1fr;
      gap: 0.3rem;
    }

    .app-icon .google-app {
      margin-right: 0rem;
    }

    .google-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h5 {
        font-size: 0.9rem;
        margin-bottom: 0rem;
        font-weight: 600;
      }
    }

    .ios-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h4 {
        font-size: 0.9rem;
        margin-bottom: 0rem;
        font-weight: 600;
        margin-top: 0rem;
        margin-left: 0rem;
      }
    }

    .google {
      display: flex;
      gap: 0.4rem;
      padding: 0.4rem;
      max-width: 12rem;
    }
    .ios {
      display: flex;
      gap: 0.4rem;
      padding: 0.4rem;
      max-width: 12rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 3rem;

    .home-bg {
      padding: 2rem;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;

      .col-md-7,
      .col-md-5 {
        width: 100%;
      }
    }

    .video {
      width: 100%;
      margin-left: 0rem;
      height: 30rem;
      margin-top: 3rem;
    }

    .app-icon .google-app {
      margin-right: 0rem;
    }

    .google-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h5 {
        font-size: 1.3rem;
        margin-bottom: 0rem;
        font-weight: 600;
      }
    }

    .ios-text {
      p {
        margin-bottom: 0rem;
        color: #7c8087;
        font-size: 1rem;
      }
      h4 {
        font-size: 1.3rem;
        margin-bottom: 0rem;
        font-weight: 600;
        margin-top: 0rem;
        margin-left: 0rem;
      }
    }

    .google {
      display: flex;
      gap: 1rem;
    }
    .ios {
      display: flex;
      gap: 1rem;
    }

    .app-icon {
      margin-left: 0rem !important;
      display: flex !important;
      gap: 0.3rem;
    }
  }
`;

export default Header;
