import styled from "styled-components";
import SAPartners from "./images/SAPartners.svg";
import GHPartners from "./images/GHPartners.svg";
import GlobalPartners from "./images/GlobalPartners.svg";
import PaymentPartners from "./images/PaymentPartners.svg";

const Partners = () => {
  return (
    <StyleDiv>
      <div>
        <div className="text-center mb-4 header-text">
          The world's most innovative way of sending and receiving money
        </div>
        <div className="flex-items">
          <img src={SAPartners} alt="logo" />
          <img src={GHPartners} alt="logo" />
          <img src={GlobalPartners} alt="logo" />
          <img src={PaymentPartners} alt="logo" />
        </div>
      </div>
    </StyleDiv>
  );
};

const StyleDiv = styled.div`
  background: #ffffff;
  padding: 3rem 12rem;
  .flex-items {
    display: flex;
    gap: 4rem;
    justify-content: center;
    margin-top: 3rem;
  }

  .header-text {
    color: #13254a;
    font-size: 1.2rem;
    color: #13254a;
    font-weight: 500;
    opacity: 0.7;
  }
  img {
    filter: grayscale(100%);
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .flex-items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      justify-content: center;
      margin-top: 3rem;
    }

    img {
      width: 8rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 2rem 2rem 2rem;

    .flex-items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 0.3rem;
      justify-content: center;
      margin-top: 3rem;
    }

    img {
      width: 8rem;
    }
  }
`;

export default Partners;
