import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import Nav from "../Nav";
import Footer from "../Footer";
import Join from "../Join";

library.add(fab);

const Invite = () => {
  return (
    <div>
      <Nav />
      <Join />
      <Footer />
    </div>
  );
};

export default Invite;
