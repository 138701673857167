import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Nav from './Nav';
import Footer from './Footer';

const Privacy = () => {
    const [show, setShow] = useState("website")
    return (
        <div>
            <Nav />
            <div className="p-nav">
                <a href="/" className="text-dark fw-bold">Home</a><FontAwesomeIcon icon={faAngleRight} className="mg" /><small className="text-muted"> Privacy</small>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4 mt-5">
                        <div className="side-nav pd-l">
                            <ul>
                                <li><a href="/" className="fw-bold">Introduction</a> <br />
                                    <small className="mt2 fw-bold">Overview</small>
                                </li>
                                <li><a href="#website" 
                                    className="mt2 fw-bold">Website Visitor</a></li>
                                <li><a href="#info"
                                    className="mt2 fw-bold ">Gathering of Personally...</a></li>
                                <li><a href="#security"
                                    className="mt2 fw-bold ">Security</a></li>
                                <li><a href="#advert"
                                    className="mt2 fw-bold ">Advertisment</a></li>
                                <li><a href="#stat"
                                    className="mt2 fw-bold ">Aggregated statistics</a></li>
                                <li><a href="#cookies"
                                    className="mt2 fw-bold ">Cookies</a></li>
                                <li><a href="#policy"
                                    className="mt2 fw-bold ">Privacy policy Changes</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-8 mt-5">
                        <div className="content p-7">
                            <h1 className="fw-bold">Welcome to Privacy Policy</h1>
                            <h6 className="mt-3 fw-bold">YOUR PRIVACY IS CRITICALLY IMPORTANT TO US.</h6>
                            <p className="mt-5">It is 1 app policy to respect your privacy regarding any information we may collect while operating our website. This Privacy Policy applies to 1app. We respect your privacy and are committed to protecting personally identifiable information you may provide us through the Website. We have adopted this Privacy Policy to explain what information may be collected on our website, how  we use this information, and under what circumstances we may disclose the information to third parties, as one of our main priorities is the privacy of our visitors. This Privacy Policy applies only to information we collect through the website and does not apply to our collection of information from other sources.</p>
                            <p className="mt-3">This Privacy Policy, together with the Terms and conditions posted on our website, set forth the general rules and policies governing your use of our website. Depending on your activities when visiting our website, you may be required to agree to additional terms and conditions.</p>
                            <p className="mt-3">If you have additional questions or require more information about our Privacy Policy, do not hesitate to contact us through email at hi@1appgo.com</p>
                        </div>

                        <div className="accordion mt-5 mb-4">
                            <div className="accordion-item" id="website">
                                <h2>
                                    <button onClick={() => setShow("website")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne">
                                        Website Visitor
                                    </button>
                                </h2>
                                {show === "website" ?
                                    <div>
                                        <div className="accordion-body">
                                            <p> When you come to our website (investa.ng) we may collect certain information such as browser type, operating system, website visited immediately before coming to our site, etc. This information is used in an aggregated manner to analyse how people use our site, such that we can improve our service.</p>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="info">
                                <h2>
                                    <button onClick={() => { setShow("info") }} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo">
                                        Gathering of Personally-Identifying Information
                                     </button>
                                </h2>
                                {show === "info" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>You may request details of personal information that we hold about you. A small administrative fee may be payable for the provision of information. If you would like a copy of the information, which we hold about you or believe that any information we hold on you is inaccurate, out of date, incomplete, irrelevant or misleading, please email us at social@investa.ng.</p>
                                                <p>We reserve the right to refuse to provide you with information that we hold about you, in certain circumstances set out in the Privacy Act.</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="security">
                                <h2>
                                    <button onClick={() => setShow("security")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree">
                                        Security
                                    </button>
                                </h2>
                                {show === "security" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>Appia is committed to ensuring that the information you provide to us is secure. In order to prevent unauthorised access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure information and protect it from misuse, interference, loss and unauthorised access, modification and disclosure.</p>
                                                <p>The transmission and exchange of information is carried out at your own risk. We cannot guarantee the security of any information that you transmit to us, or receive from us. Although we take measures to safeguard against unauthorised disclosures of information, we cannot assure you that personal information that we collect will not be disclosed in a manner that is inconsistent with this Privacy Policy.</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="advert">
                                <h2>
                                    <button onClick={() => setShow("advert")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour">
                                        Advertisement
                                    </button>
                                </h2>
                                {show === "advert" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>Ads</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="stat">
                                <h2>
                                    <button onClick={() => setShow("stat")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive">
                                        Aggregated Statistics
                                     </button>
                                </h2>
                                {show === "stat" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>Aggregated Statistics</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="cookies">
                                <h2>
                                    <button onClick={() => setShow("cookies")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix">
                                        Cookies
                                     </button>
                                </h2>
                                {show === "cookies" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>We may from time to time use cookies on our website. Cookies are very small files which a website uses to identify you when you come back to the site and to store details about your use of the site. Cookies are not malicious programs that access or damage your computer. Most web browsers automatically accept cookies but you can choose to reject cookies by changing your browser settings. However, this may prevent you from taking full advantage of our website. Our website may from time to time use cookies to analyses website traffic and help us provide a better website visitor experience. In addition, cookies may be used to serve relevant ads to website visitors through third party services such as Google Adwords. These ads may appear on this website or other websites you visit.</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                            <div className="accordion-item" id="policy">
                                <h2>
                                    <button onClick={() => setShow("policy")} className="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven">
                                        Privacy policy changes
                                     </button>
                                </h2>
                                {show === "policy" ?
                                    <div>
                                        <div className="accordion-body">
                                            <div className="accordion-body">
                                                <p>Please be aware that we may change this Privacy Policy in the future. We may modify this Policy at any time, in our sole discretion and all modifications will be effective immediately upon our posting of the modifications on our website or notice board. Please check back from time to time to review our Privacy Policy.</p>
                                            </div>
                                        </div>
                                    </div>
                                    : ""}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Privacy;
