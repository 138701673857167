import React from 'react';
import Subscribe from './Subscribe';
import FooterBase from './FooterBase';


const Footer = () => {
    return (
        <div>
            <Subscribe />
            <FooterBase />
        </div>
    )
}

export default Footer;

