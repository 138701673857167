import { Link } from "react-router-dom";
import logo2 from "./images/logo2.png";
import HuaweiLogo from "./images/Huawei.svg";
import twitter from "./images/twitter.svg";
import fb from "./images/fb.svg";
import google from "./images/google.svg";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

library.add(fab);

const FooterBase = () => {
  return (
    <StyledDiv>
      <div className="container-fluid footer mt-5">
        <div className="container pd-t2">
          <div className="row">
            <div className="col-md-5 f-head mt-4">
              <Link to="/" className="text-white">
                <img src={logo2} alt="" className="img-fluid" />{" "}
                <span className="logo-text">Appia</span>
              </Link>
              <p className="text-footer mt-4 fs-5">
                Your ultimate solution for all
                <br /> transactions.
              </p>
            </div>
            <div className="col-md-4 f-links mt-4">
              <ul className="q-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
                <li>
                  <Link to="/">FAQ</Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 mt-3" id="download">
              <div className="app-icon3 mt-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.appia.ng"
                  className="google3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={["fab", "google-play"]}
                    className="google-icon"
                  />
                  <div className="google-text">
                    <p>Get it on</p>
                    <h5 className="google-link">Google Play</h5>
                  </div>
                </a>
                <a
                  href="https://apps.apple.com/us/app/appiang/id1643401938"
                  className="google3 mt-3"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FontAwesomeIcon
                    icon={["fab", "apple"]}
                    className="ios-icon"
                  />
                  <div className="google-text">
                    <p>Get it on</p>
                    <h4 className="google-link">App Store</h4>
                  </div>
                </a>
                <a
                  href="https://url.cloud.huawei.com/iwRSfDRJL2?shareTo=qrcode"
                  target="_blank"
                  rel="noreferrer"
                  className="google3 mt-3"
                >
                  <img src={HuaweiLogo} className="ios-icon" alt="icon" />
                  <div className="google-text">
                    <p>Available on the</p>
                    <h4 className="google-link">AppGallery</h4>
                  </div>
                </a>
              </div>
            </div>
            <hr className="mt-5" />

            <div className="last-area mt-5">
              <div className="row">
                <div className="col-md-6">
                  <p className="text-footer fw-bold">
                    <small>&copy; Appia 2021, All Rights Reserved</small>
                  </p>
                </div>
              </div>

              <div className="text-right t-icon">
                <a
                  href="https://twitter.com/AppiaApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={twitter} alt="icon" />
                </a>
                <a
                  href="https://www.facebook.com/AppiaApp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={fb} alt="icon" />
                </a>
                <a
                  href="https://www.instagram.com/appiaapp/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={google} alt="icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  padding: 0rem 12rem 3rem 12rem;
  margin-top: 6rem;
  background: #1d293f;
  .logo-text {
    margin-left: 1rem;
    font-weight: 800;
    font-size: 2rem;
  }

  .google-link {
    font-weight: 700 !important;
    color: #1d293f !important;
    font-size: 1.3rem !important;
  }

  .google3 {
    display: flex;
    align-items: center;
    background: #fff;
    gap: 1.5rem;
    border: 2px solid #e2e4e8;
    border-radius: 5px;
    padding: 0rem 1rem;
    width: 15rem;
    p {
      margin-bottom: 0.5rem;
      text-align: left;
      color: #7c8087;
    }
  }

  .last-area {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 0rem !important;
    margin-top: 0rem;

    .f-links {
      margin-top: 0rem !important;
    }
    .footer {
      margin-top: 0rem;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 0rem !important;
    .google3 {
      width: 12rem;
    }

    .pd-t2 {
      margin: 0rem !important;
    }

    .q-link {
      padding-left: 0rem;
    }
  }
`;
export default FooterBase;
