import styled from "styled-components";
import { Link } from "react-router-dom";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CarouselAppia = () => {
  return (
    <StyledDiv>
      <div className="row  align-items-center row-title">
        <div className="col-md-7">
          <h3 className="heading-text">
            With appia you can do all this and More. Should have our unique
            feature:
          </h3>
        </div>
        <div className="col-md-5">
          <div className="float-end">
            <Link to="" className="bg-Aleft text-purple">
              <FontAwesomeIcon icon={faAngleLeft} />
            </Link>
            <Link to="" className="bg-ARight text-white">
              <FontAwesomeIcon icon={faAngleRight} />
            </Link>
          </div>
        </div>
      </div>
      <div className="row ">
        <div className="col-md-5 mt-5 p-1">
          <div className="e-account">
            <h3 className="fw-bold text-white pd-t1">
              Generate
              <br /> Virtual ATM cards
            </h3>
            <p className="mt-5 text-white fs-5">
              Not with a debit card? No worries, with Appia virtual card, safely
              make online transactions anytime, anywhere.
            </p>
          </div>
        </div>
        <div className="col-md-3 mt-5  p-1">
          <div className="v-card">
            <h3 className="fw-bold text-white pd-t1">
              Utility & <br />
              Bill Payment
            </h3>
            <p className="mt-5 text-white fs-5">
              Paying your utility bills just got easier, faster and more secure
              with Appia
            </p>
          </div>
        </div>
        <div className="col-md-4 mt-5  p-1">
          <div className="bill">
            <h3 className="fw-bold text-white pd-t1">
              Escrow <br /> Account
            </h3>
            <p className="mt-5 text-white fs-5">
              No one should be a victim to scammers Appia provides payment
              security for both businesses and individuals.
            </p>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};

const StyledDiv = styled.div`
  background: #f9f9f9;
  padding: 5rem 12rem;

  .heading-text {
    font-weight: 700;
    color: #000000;
    line-height: 2.5rem;
  }

  .bg-Aleft {
    border: 1px solid #6713e1;
    background: linear-gradient(
      180deg,
      rgba(142, 45, 226, 0.1) 0%,
      rgba(74, 0, 224, 0.1) 100%
    );
    border-radius: 50%;
    padding: 9px 15px;
    margin-right: 10px;
  }

  .bg-ARight {
    background: linear-gradient(180deg, #8a1ae2 0%, #2e5fbf 100%);
    border: 1px solid #e6e6e6;
    box-shadow: 0px 32px 84px rgba(14, 86, 124, 0.165308);
    border-radius: 50%;
    padding: 9px 15px;
    margin-right: 10px;
  }

  .e-account {
    background: linear-gradient(180deg, #8a1ae2 0%, #2e5fbf 100%);
    border-radius: 8px;
    height: 100% !important;
    width: 100%;
    padding: 5rem 2rem;
  }

  .v-card {
    background: linear-gradient(180deg, #2ebf91 0%, #1055db 100%);
    border-radius: 8px;
    height: 100% !important;
    width: 100%;
    padding: 5rem 2rem;
  }

  .bill {
    background: linear-gradient(165.97deg, #f244fc 2.15%, #f90909 140.36%);
    border-radius: 8px;
    height: 100% !important;
    width: 100%;
    margin-right: 3rem !important;
    padding: 5rem 2rem;
  }

  @media only screen and (min-width: 320px) and (max-width: 767px) {
    padding: 3rem 2rem 2rem 2rem;

    .row-title {
      display: flex !important;
      flex-direction: row !important;
      h3 {
        font-size: 1rem !important;
      }
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    padding: 3rem 2rem 2rem 2rem;

    .e-account,
    .v-card,
    .bill {
      padding: 5rem 1rem;
    }
  }
`;

export default CarouselAppia;
